import { graphql } from 'gatsby';
import React from 'react';
import { ContactInfo } from '../../components/ContactInfo';
import { Hero } from '../../components/Hero';
import { Layout } from '../../components/Layout';
import { Section } from '../../components/Section';
import { styled } from '../../styles/styled';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ExternalLink } from 'react-feather';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';

interface ResourceEntity {
  __typename: 'ContentfulResource';
  id: string;
  title: string;
  description: {
    description: string;
  };
  url: string;
  file: {
    file: {
      contentType: string;
      fileName: string;
      url: string;
      details: {
        size: number;
      };
    };
  };
}

interface ResourceCollectionEntity {
  __typename: 'ContentfulResourceCollection';
  id: string;
  title: string;
  resources: ResourceEntity[];
}

const isResourceCollectionEntity = (
  item: ResourceEntity | ResourceCollectionEntity
): item is ResourceCollectionEntity => {
  return !!item && item.__typename === 'ContentfulResourceCollection';
};

interface Props {
  data: {
    page: {
      title: string;
      subtitle?: string;
      resourceCollections?: Array<ResourceCollectionEntity | ResourceEntity>;
    };
    resources: {
      edges: {
        node: ResourceCollectionEntity | ResourceEntity;
      }[];
    };
  };
}

const Ul = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
`;

const Li = styled.li`
  padding: 24px;
  border-bottom: 1px solid #ececec;
  &:last-child {
    border-bottom: 0;
  }
  & > ${Ul} {
    padding-left: 24px;
  }
`;

const Resource: React.FC<{ resource: ResourceEntity }> = ({ resource }) => {
  const link = resource.file ? resource.file.file.url : resource.url;

  return (
    <Li>
      <Typography display="block" variant="subtitle1">
        {resource.title}
      </Typography>
      <Link
        display="block"
        color="secondary"
        variant="body2"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        gutterBottom
      >
        {resource.file ? (
          `${resource.file.file.fileName} | ${(
            resource.file.file.details.size / 1000000
          ).toFixed(2)} MB`
        ) : (
          <>
            {link} <ExternalLink size="0.875em" />
          </>
        )}
      </Link>
      {resource.description && (
        <Typography
          paragraph
          display="block"
          variant="body2"
          color="textSecondary"
        >
          {resource.description.description}
        </Typography>
      )}
    </Li>
  );
};

export default ({ data: { page } }: Props) => {
  return (
    <Layout title={page.title}>
      <Hero title={page.title} subtitle={page.subtitle} />
      <Section>
        <Ul>
          {page.resourceCollections &&
            page.resourceCollections.map((resource) => {
              if (isResourceCollectionEntity(resource)) {
                return (
                  <Li>
                    <Typography variant="h6" gutterBottom={true}>
                      {resource.title}
                    </Typography>
                    <Ul>
                      {resource.resources.map((other) => (
                        <Resource key={other.id} resource={other} />
                      ))}
                    </Ul>
                  </Li>
                );
              }

              return <Resource resource={resource} />;
            })}
        </Ul>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  fragment ResourceData on ContentfulResource {
    title
    id
    description {
      description
    }
    file {
      id
      file {
        url
        details {
          size
        }
        fileName
      }
    }
    url
  }

  query ResourcesPageQuery {
    page: contentfulResourcePage {
      id
      title
      resourceCollections {
        __typename
        ... on ContentfulResourceCollection {
          id
          title
          resources {
            ...ResourceData
          }
        }
      }
    }
    resources: allContentfulResource {
      edges {
        node {
          id
          title
          url
          description {
            description
          }
          file {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
          }
        }
      }
    }
  }
`;
